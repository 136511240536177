import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders a card-grid that features consumer-facing information about the product.
 */
class PurchaseRewards extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-customer-logged-in',
      'data-login-url',
      'data-value'
    ];
  }

  readonly dataset!: {
    /**
     * Boolean represented as string to indicate if customer is logged into the system
     */
    customerLoggedIn: 'false' | 'true';

    /**
     * URL as a string to log into the website as a customer
     */
    loginUrl: string;

    /**
     * Boolean represented as string to render text stacked
     */
    stacked: 'false' | 'true';

    /**
     * Point value for particular purchase of product being viewed
     */
    value: string;
  };

  public shadowRoot!: ShadowRoot;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.render();
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && newValue !== oldValue) {
      this.render();
    }
  }

  private render() {
    const value = this.shadowRoot.getElementById('earn-value');
    value.textContent = this.dataset.value;

    const login = this.shadowRoot.querySelector<HTMLAnchorElement>('#login');
    login.href = this.dataset.loginUrl;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'purchase-rewards': PurchaseRewards;
  }
}

if (!customElements.get('purchase-rewards')) {
  customElements.define('purchase-rewards', PurchaseRewards);
}
